import React from "react";

import AppNavigation  from "./AppNavigation";

export default class App extends React.Component {
  render() {
    return (
        <AppNavigation />
    );
  }
}
